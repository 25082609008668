export const ROUTE_PATH = {
  HOME_PAGE: '/',
  STUDY_SUMMARY: '/study-summary',
  POPULATION_PAGE: '/population',
  QNQ_PAGE: '/qnq',
  GROUPING_PAGE: '/grouping',
  SURVEY_PAGE: '/survey',
  CREATE_SURVEY: '/create-survey',
  NOTIFICATION_PAGE: '/notification',
  NOTIFICATION_DETAIL_PAGE: '/notification-detail',
  CREATE_NOTIFICATION: '/configure-notification',
  QRE_PAGE: '/qre',
  ANALYTICS_PAGE: '/analytics',
  NOT_FOUND_PAGE: '/not-found',

  RND_SURVEY_DASHBOARD_PAGE: '/rnd-dashboard',
};

export function getRoutePathWithStudyPeriod(routePath, studyPeriod) {
  if (studyPeriod === undefined) return routePath;
  return `/${studyPeriod}${routePath}`;
}

export function getRoutePathWithStudyPeriodPlaceholder(routePath) {
  return getRoutePathWithStudyPeriod(routePath, ':studyPeriod');
}
