import { Button, Container, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { forEach, get, isEmpty, isNull } from 'lodash';
import { RESOURCE } from '../../auth/constants/rbac_constants';
import { BRC_ITEMS_PAGE } from '../../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../../common/components/with_page/Page';
import { getStudyPeriod } from '../../../common/constants/study_period';
import CreateSurveysComponent from './CreateSurveyComponent';
import './css/style.css';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../common/constants/route_paths';
import { configureSurveysAction } from './redux/surveyConfiguration';
import { SURVEY_TYPE } from '../../../common/components/pdf_generator/templates/SurveyPDFTemplate/utils/DataUtils';
import { getConfigureSurveyPayload } from './utils/SurveyUtils';

const CreateSurveyPage = ({
  studyPeriod,
  pageHeaderName,
  navigateTo,
  configureSurveys,
  entityId,
  studyType,
}) => {
  const initialState = {
    LaborSec41: { releaseDate: '', dueDate: '', template: null },
    LaborSec41Expanded: { releaseDate: '', dueDate: '', template: null },
    Contract: { releaseDate: '', dueDate: '', template: null },
  };
  const [state, setState] = useState(initialState);
  const navigateToSurveyPage = () => {
    navigateTo({
      pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.SURVEY_PAGE, studyPeriod),
    });
  };
  const onConfirmCreateSurvey = () => {
    forEach([SURVEY_TYPE.LABOR, SURVEY_TYPE.CONTRACT], surveyType => {
      configureSurveys({
        body: getConfigureSurveyPayload(state, surveyType),
        onSuccess: navigateToSurveyPage,
      });
    });
  };
  const isConfirmButtonDisabled = () => {
    if (
      isEmpty(state.LaborSec41.releaseDate) ||
      isEmpty(state.LaborSec41.dueDate) ||
      isNull(state.LaborSec41.template) ||
      isEmpty(state.LaborSec41Expanded.releaseDate) ||
      isEmpty(state.LaborSec41Expanded.dueDate) ||
      isNull(state.LaborSec41Expanded.template) ||
      isEmpty(state.Contract.releaseDate) ||
      isEmpty(state.Contract.dueDate) ||
      isNull(state.Contract.template)
    ) {
      return true;
    }
    return false;
  };
  const content = (
    <SpaceBetween size="l">
      <Header variant="h1">{pageHeaderName}</Header>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={navigateToSurveyPage}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onConfirmCreateSurvey}
              disabled={isConfirmButtonDisabled()}
            >
              Confirm
            </Button>
          </SpaceBetween>
        }
        className="create-survey-form"
      >
        <Container header={<Header>Default</Header>}>
          <SpaceBetween size="xl" className="create-survey-container">
            <CreateSurveysComponent
              surveyType={SURVEY_TYPE.LABOR}
              state={state}
              setState={setState}
              populationName=" - Sec. 41"
              populationType="LaborSec41"
            />
            <CreateSurveysComponent
              surveyType={SURVEY_TYPE.LABOR}
              state={state}
              setState={setState}
              populationName=" - Sec. 41 Expanded"
              populationType="LaborSec41Expanded"
            />
            <CreateSurveysComponent
              surveyType={SURVEY_TYPE.CONTRACT}
              state={state}
              setState={setState}
              populationType="Contract"
            />
          </SpaceBetween>
        </Container>
      </Form>
    </SpaceBetween>
  );
  return (
    <Page
      PageId={RESOURCE.PAGE_SURVEY}
      isPageAccessControlled={false}
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.CREATE_SURVEY(studyPeriod, entityId, studyType)}
    />
  );
};

CreateSurveyPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  pageHeaderName: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
  configureSurveys: PropTypes.func.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
};

CreateSurveyPage.defaultProps = {
  pageHeaderName: 'Create survey(s)',
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId', undefined),
  studyType: get(state, 'studyType.studyType'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigateTo: payload => push(payload),
      configureSurveys: configureSurveysAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurveyPage);
