import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../../constants/route_paths';
import { STUDY_TYPE } from '../../../../../redux/reducers/studyTypeReducers';
import { ENTITY_LABELS } from '../../../../../redux/reducers/entityIdReducers';

const getDashboardBRItem = (studyPeriod, entityId) => {
  return entityId
    ? [
        {
          text: `${ENTITY_LABELS[entityId] || entityId}`,
        },
        {
          text: 'Home',
          href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, studyPeriod)}`,
        },
      ]
    : [
        {
          text: 'Home',
          href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, studyPeriod)}`,
        },
      ];
};

const getPopulationPageBRItem = studyPeriod => {
  return {
    text: 'Population',
    href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.POPULATION_PAGE, studyPeriod)}`,
  };
};

const getCurrentStudyPeriodBRItem = (studyPeriod, entityId, studyType) => {
  return entityId
    ? [
        {
          text: `${ENTITY_LABELS[entityId] || entityId}`,
        },
        {
          text:
            STUDY_TYPE.US_DUAL_ALLOCATION === studyType
              ? `Study Period ${studyPeriod} dual allocation`
              : `Study Period ${studyPeriod}`,
          href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, studyPeriod)}`,
        },
      ]
    : [
        {
          text:
            STUDY_TYPE.US_DUAL_ALLOCATION === studyType
              ? `Study Period ${studyPeriod} dual allocation`
              : `Study Period ${studyPeriod}`,
          href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, studyPeriod)}`,
        },
      ];
};

const getNotificationPageBRItem = studyPeriod => {
  return {
    text: 'Notification',
    href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.NOTIFICATION_PAGE, studyPeriod)}`,
  };
};

const getSurveyPageBRItem = studyPeriod => {
  return {
    text: 'Surveys',
    href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.SURVEY_PAGE, studyPeriod)}`,
  };
};

const getBRCItemWithText = text => {
  return {
    text,
  };
};

const getStudySummaryPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType)];
};

const getQNQPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getBRCItemWithText('QNQ'),
  ];
};

const getSurveyPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getBRCItemWithText('Surveys'),
  ];
};

const getCreateSurveyPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getSurveyPageBRItem(studyPeriod),
    getBRCItemWithText('Create surveys'),
  ];
};

const getPopulationPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getBRCItemWithText('Population'),
  ];
};

const getGroupingPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getPopulationPageBRItem(studyPeriod),
    getBRCItemWithText('Grouping'),
  ];
};

const getNotificationPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getBRCItemWithText('Notification'),
  ];
};

const getNotificationDetailsPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getNotificationPageBRItem(studyPeriod),
  ];
};

const getCreateNotificationPageBRCItems = (studyPeriod, entityId, studyType, brcText) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getNotificationPageBRItem(studyPeriod),
    getBRCItemWithText(brcText),
  ];
};

const getQREPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getBRCItemWithText('QRE'),
  ];
};

const getAnalyticsPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [
    ...getCurrentStudyPeriodBRItem(studyPeriod, entityId, studyType),
    getBRCItemWithText('Analytics'),
  ];
};

// StudyType is ignored for not found
const getNotFoundPageBRCItems = (studyPeriod, entityId, studyType) => {
  return [getDashboardBRItem(studyPeriod, entityId, studyType), getBRCItemWithText('Not found!')];
};

export const NOT_AUTHORIZED_TO_ACCESS_PAGE_BRC_ITEMS = [
  getBRCItemWithText('You are not authorized to access this page!'),
];

export const BRC_ITEMS_PAGE = {
  STUDY_SUMMARY: getStudySummaryPageBRCItems,
  QNQ: getQNQPageBRCItems,
  SURVEY: getSurveyPageBRCItems,
  CREATE_SURVEY: getCreateSurveyPageBRCItems,
  POPULATION: getPopulationPageBRCItems,
  GROUPING: getGroupingPageBRCItems,
  NOTIFICATION: getNotificationPageBRCItems,
  NOTIFICATION_DETAILS: getNotificationDetailsPageBRCItems,
  CREATE_NOTIFICATION: getCreateNotificationPageBRCItems,
  QRE: getQREPageBRCItems,
  ANALYTICS: getAnalyticsPageBRCItems,
  NOT_FOUND: getNotFoundPageBRCItems,
};
