import { DEFAULT_BUSINESS_ID } from '../../../common/config/api_constants';

export const ENTITY_ID = {
  ADCL: 'ADCL',
  ADCS: 'ADCS',
  ET: 'ET',
  IMDBUK: 'IMDBUK',
  VEEQO: 'VEEQO',
};

export const ENTITY_LABELS = {
  [ENTITY_ID.ADCL]: 'Amazon Dev Centre (Scotland) Ltd.',
  [ENTITY_ID.ADCS]: 'Amazon Dev Centre (London) Ltd.',
  [ENTITY_ID.ET]: 'Evi Technologies Ltd.',
  [ENTITY_ID.IMDBUK]: 'IMDb Services Limited (UK)',
  [ENTITY_ID.VEEQO]: 'Veeqo Ltd.',
};

export const ENTITY_ID_VALUES = Object.values(ENTITY_ID);

const initialState = {
  entityId: DEFAULT_BUSINESS_ID === 'RadicalUK' ? ENTITY_ID.ADCL : undefined,
};

const entityIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ENTITY_ID':
      return { ...state, entityId: action.payload.entityId };
    default:
      return state;
  }
};

export default entityIdReducer;
